import React from "react";
import useScript from "../hooks/useScript"
//import { useMixpanelTracker } from "../hooks/useMixpanelTracker";

// import NewsletterImage1 from '../images/photos/newsletter_image_1.png';

const NewsletterFormHorizontal = () => {
 /*  useMixpanelTracker("9", (mixpanel, formData) => {
    mixpanel.alias(formData["email"]);
    mixpanel.identify(formData["email"]);

    mixpanel.track("Newsletter form submission", {
      Email: formData["email"],
    });

    mixpanel.people.set({
      $email: formData["email"],
    });
  }); */

  return (
    <section className="bg-primary-3 text-light">
      <div className="container px-4 px-md-0">
        <div className="row justify-content-center">

          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
            <h4 className="h2 text-left mb-1">
              Exclusive UX Articles & Strategies
            </h4>
            <p className="lead text-left">
              for Startups, UX Designers & Entrepreneurs
            </p>
          </div>

          <div id="activecampaign-form" className="col-12 col-lg-6 d-flex flex-column justify-content-center">
            <div id="activecampaign-form__wrapper">
              {/*<img src={NewsletterImage1} class="newsletter_image" alt="" />*/}
              <div className=" _form_9"></div>
              {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=9")}
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default NewsletterFormHorizontal;
