import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const CardDownload = ({ cardData }) => (
  <Link to={`/download-ux-resources/${cardData.slug}`}>
    <div className="card">
      <GatsbyImage
        image={cardData.cardImage.gatsbyImageData}
        alt="card"
        className="card-img-top"
        loading="lazy"
      />
      <div className="card-body d-flex flex-column">
        <h4>{cardData.title}</h4>
      </div>
    </div>
  </Link>
);

CardDownload.propTypes = {
  cardData: PropTypes.object.isRequired
};

export default CardDownload;
