import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import CardDownload from "../components/CardDownload";
import NewsletterFormHorizontal from "../components/NewsletterFormHorizontal";
import { UxDesignResourcesJsonLd } from "../components/jsonld/UxDesignResourcesJsonLd";

const DownloadUxResourcesPage = ({ data, location: { pathname } }) => {
  const DownloadCardsData = data.allDatoCmsDownload.edges;

  return (
    <Layout
      isHeaderDark={true}
      headerBgClass="bg-primary-3"
      meta={{ seo: data.datoCmsPage.seo, path: pathname }}
    >
      <UxDesignResourcesJsonLd />
      <div className="download-ux-resources-page">
        <section className="first-screen bg-primary-3 text-light d-flex text-center align-items-end py-6">
          {/*<img src={ImagePortfolio6} alt="portfolio" className="bg-img jarallax-img" />*/}
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="display-4">Downloads</h1>
                <span className="lead">
                  Templates, Checklists & Cheat Sheet
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-primary-alt">
          <div className="container">
            {/* <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-center mb-5">
                  <ul class="nav">
                    <li class="nav-item">
                      <span class="nav-link active">All topics</span>
                    </li>
                    <li class="nav-item">
                      <span class="nav-link">UX Research</span>
                    </li>
                    <li class="nav-item">
                      <span class="nav-link">UX/UI</span>
                    </li>
                    <li class="nav-item">
                      <span class="nav-link">Startups</span>
                    </li>
                    <li class="nav-item">
                      <span class="nav-link">Working remotely </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}

            <div className="row">
              {DownloadCardsData.map(({ node }) => (
                <div className="col-md-6 col-lg-6" key={node.id}>
                  <CardDownload cardData={node} />
                </div>
              ))}
            </div>
          </div>
        </section>

        <NewsletterFormHorizontal />
      </div>
    </Layout>
  );
};

export default DownloadUxResourcesPage;

export const query = graphql`
  query DownloadsPageQuery {
    datoCmsPage(slug: { eq: "download-ux-resources" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }

    allDatoCmsDownload(sort: { fields: meta___createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          author {
            fullName
          }
          cardImage {
            gatsbyImageData(width: 1000,  layout: CONSTRAINED)
          }
          slug
        }
      }
    }
  }
`;
